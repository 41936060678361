import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {accountsSlice} from "../app/pages/Accounts/_redux/accountsSlice";
import {transactionsSlice} from "../app/pages/Transactions/_redux/transactionsSlice";
import {domainsSlice} from "../app/pages/Domains/_redux/domainsSlice";
import {gatewaysSlice} from "../app/pages/Domains/domain-gateways/_redux/gatewaysSlice";
import {usersSlice} from "../app/pages/Users/_redux/usersSlice";
import {settingsSlice} from "../app/pages/Settings/_redux/settingsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  domains: domainsSlice.reducer,
  accounts: accountsSlice.reducer,
  transactions: transactionsSlice.reducer,
  gateways: gatewaysSlice.reducer,
  users: usersSlice.reducer,
  settings: settingsSlice.reducer,

});

export function* rootSaga() {
  yield all([auth.saga()]);
}
