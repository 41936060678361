import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import AccountsFilter from "./accounts-filter/AccountsFilter";
import AccountsTable from "./accounts-table/AccountsTable";
import { useAccountsUIContext } from "./AccountsUIContext";

export function AccountsCard() {
  const accountsUIContext = useAccountsUIContext();
  const accountsUIProps = useMemo(() => {
    return {
      ids: accountsUIContext.ids,
      newAccountButtonClick: accountsUIContext.newAccountButtonClick,
      exportAccountsButtonClick: accountsUIContext.exportAccountsButtonClick,
    };
  }, [accountsUIContext]);

  return (
    <Card>
      <CardHeader title={<FormattedMessage id="ACCOUNTS.TITLE" />}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-warning"
            onClick={accountsUIProps.exportAccountsButtonClick}
          >
            <FormattedMessage id="COMMON.TABLE.EXPORT" />
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <AccountsFilter />
        <AccountsTable />
      </CardBody>
    </Card>
  );
}
