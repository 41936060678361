import React from "react";
import { injectIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";

const AccountWithdrawDialogHeader = ({ intl }) =>  {
  // Accounts Redux state
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.accounts.actionsLoading,
    }),
    shallowEqual
  );

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{intl.formatMessage({id: 'COMMON.TITLE.WITHDRAW'})}</Modal.Title>
      </Modal.Header>
    </>
  );
}

export default injectIntl(AccountWithdrawDialogHeader);