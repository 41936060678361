import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import DomainsFilter from "./domains-filter/DomainsFilter";
import DomainsTable from "./domains-table/DomainsTable";
import { useDomainsUIContext } from "./DomainsUIContext";

export function DomainsCard() {
  const domainsUIContext = useDomainsUIContext();
  const domainsUIProps = useMemo(() => {
    return {
      ids: domainsUIContext.ids,
      newDomainButtonClick: domainsUIContext.newDomainButtonClick
    };
  }, [domainsUIContext]);

  return (
    <Card>
      <CardHeader title={<FormattedMessage id="DOMAINS.TITLE" />}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={domainsUIProps.newDomainButtonClick}
          >
            <FormattedMessage id="COMMON.TABLE.ADD" />
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <DomainsFilter />
        <DomainsTable />
      </CardBody>
    </Card>
  );
}
