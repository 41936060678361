import React, {useEffect} from "react";
import { injectIntl } from "react-intl";
import {shallowEqual, useSelector} from "react-redux";
import {LoadingDialog} from "../../../../_metronic/_partials/controls";

const GatewaysLoadingDialog = ({ intl }) => {
  const { isLoading } = useSelector(
    state => ({ isLoading: state.gateways.listLoading }),
    shallowEqual
  );
  useEffect(() => {}, [isLoading]);
  return <LoadingDialog isLoading={isLoading} text={intl.formatMessage({id: 'COMMON.DIALOG.LOADING'})} />;
}

export default injectIntl(GatewaysLoadingDialog);