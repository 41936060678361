// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  Textarea
} from "../../../../../_metronic/_partials/controls";

// Validation schema
const GatewayEditSchema = Yup.object().shape({

});

const GatewayEditForm = ({ intl, saveGateway, gateway, actionsLoading, onHide }) => {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={gateway}
        validationSchema={GatewayEditSchema}
        onSubmit={(values) => saveGateway(values)}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="row">
                  <div className="form-group col-lg-4">
                    <Field
                      name="name"
                      component={Input}
                      label={intl.formatMessage({id: 'GATEWAYS.FIELD.NAME'})}
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <Select name="component" label={intl.formatMessage({id: 'GATEWAYS.FIELD.COMPONENT'})}>
                      <option value="SAGEPAY">SagePay</option>
                      <option value="PAYPAL">PayPal</option>
                    </Select>
                  </div>
                  <div className="col-lg-4">
                    <Select name="active" label={intl.formatMessage({id: 'GATEWAYS.FIELD.TYPE'})}>
                      <option value="0">{intl.formatMessage({id: 'GATEWAYS.TYPE.INACTIVE'})}</option>
                      <option value="1">{intl.formatMessage({id: 'GATEWAYS.TYPE.ACTIVE'})}</option>
                    </Select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-lg-12">
                      <Field
                        name="responseUrl"
                        component={Input}
                        label={intl.formatMessage({id: 'GATEWAYS.FIELD.RESPONSE'})}
                      />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-lg-12">
                      <Field
                        name="settings"
                        component={Textarea}
                        label={intl.formatMessage({id: 'GATEWAYS.FIELD.SETTINGS'})}
                      />
                  </div>                  
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="COMMON.DIALOG.CANCEL" />
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                <FormattedMessage id="COMMON.DIALOG.SAVE" />
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}

export default injectIntl(GatewayEditForm);