import React from "react";
import { Route } from "react-router-dom";
import AccountsLoadingDialog from "./accounts-loading-dialog/AccountsLoadingDialog";
import { AccountEditDialog } from "./account-edit-dialog/AccountEditDialog";
import { AccountDepositDialog } from "./account-deposit-dialog/AccountDepositDialog";
import { AccountWithdrawDialog } from "./account-withdraw-dialog/AccountWithdrawDialog";
import { AccountsExportDialog } from "./accounts-export-dialog/AccountsExportDialog";
import { AccountsUIProvider } from "./AccountsUIContext";
import { AccountsCard } from "./AccountsCard";

export function AccountsPage({ history }) {
  const accountsUIEvents = {
    exportAccountsButtonClick: () => {
      history.push("/accounts/export");
    },
    openEditAccountDialog: (id) => {
      history.push(`/accounts/${id}/edit`);
    },
    openDepositAccountDialog: (id) => {
      history.push(`/accounts/${id}/deposit`);
    },
    openWithdrawAccountDialog: (id) => {
      history.push(`/accounts/${id}/withdraw`);
    }
  }

  return (
    <AccountsUIProvider accountsUIEvents={accountsUIEvents}>
      <AccountsLoadingDialog />
      <Route path="/accounts/export">
        {({ history, match }) => (
          <AccountsExportDialog
            show={match != null}
            onHide={() => {
              history.push("/accounts");
            }}
          />
        )}
      </Route>
      <Route path="/accounts/:id/edit">
        {({ history, match }) => (
          <AccountEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/accounts");
            }}
          />
        )}
      </Route>
      <Route path="/accounts/:id/deposit">
        {({ history, match }) => (
          <AccountDepositDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/accounts");
            }}
          />
        )}
      </Route>
      <Route path="/accounts/:id/withdraw">
        {({ history, match }) => (
          <AccountWithdrawDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/accounts");
            }}
          />
        )}
      </Route>
      <AccountsCard />
    </AccountsUIProvider>
  );
}