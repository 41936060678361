import axios from "axios";

export const BASE_URL = "transactions";

// CREATE =>  POST: add a new transaction to the server
export function createTransaction(transaction) {
  return axios.post(BASE_URL, transaction);
}

// READ
// export function getAllTransactions() {
//   return axios.get(BASE_URL);
// }

export function getTransactionById(transactionId) {
  return axios.get(`${BASE_URL}/${transactionId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findTransactions(queryParams) {
  return axios.post(`${BASE_URL}/index`, { queryParams });
}

export function exportTransactions(queryParams) {
  return axios.post(`${BASE_URL}/export`, { queryParams });
}

// UPDATE => PUT: update the transaction on the server
export function updateTransaction(transaction) {
  return axios.put(`${BASE_URL}/${transaction._id}`, transaction);
}

// DELETE => delete the transaction from the server
export function deleteTransaction(transactionId) {
  return axios.delete(`${BASE_URL}/${transactionId}`);
}
