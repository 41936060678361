import React from "react";
import {
    StatsWidget11,
    StatsWidget12
} from "../widgets";
export function Demo1Dashboard() {
    return (<>
            <div className="row">
                <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
                    <StatsWidget11 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
                    <StatsWidget12 className="card-stretch gutter-b"/>
                </div>
            </div>
    </>);
}
