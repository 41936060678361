// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { injectIntl } from "react-intl";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/domainsActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  PaginationMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../DomainsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useDomainsUIContext } from "../DomainsUIContext";

const DomainsTable = ({ intl }) => {
  // Domains UI Context
  const domainsUIContext = useDomainsUIContext();
  const domainsUIProps = useMemo(() => {
    return {
      ids: domainsUIContext.ids,
      setIds: domainsUIContext.setIds,
      queryParams: domainsUIContext.queryParams,
      setQueryParams: domainsUIContext.setQueryParams,
      openEditDomainDialog: domainsUIContext.openEditDomainDialog,
      openDeleteDomainDialog: domainsUIContext.openDeleteDomainDialog,
    };
  }, [domainsUIContext]);

  // Getting curret state of domains list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({
      currentState: state.domains,
    }),
    shallowEqual
  );
  
  const { totalCount, entities, listLoading } = currentState;

  // Domains Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    domainsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchDomains(domainsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainsUIProps.queryParams, dispatch]);
  // Table columns
  let columns = [
    {
      dataField: "id",
      hidden: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: intl.formatMessage({id: 'COMMON.TABLE.NAME'}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "key",
      text: intl.formatMessage({id: 'COMMON.TABLE.KEY'}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "currency",
      text: intl.formatMessage({id: 'COMMON.TABLE.CURRENCY'}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "active",
      text: intl.formatMessage({id: 'COMMON.TABLE.ACTIVE'}),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.ActiveColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "modified",
      text: intl.formatMessage({id: 'COMMON.TABLE.MODIFIED'}),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DateColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: intl.formatMessage({id: 'COMMON.TABLE.ACTIONS'}),
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditDomainDialog: domainsUIProps.openEditDomainDialog,
        openDeleteDomainDialog: domainsUIProps.openDeleteDomainDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    }
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: domainsUIProps.queryParams.pageSize,
    page: domainsUIProps.queryParams.pageNumber,
    paginationTotalRenderer: PaginationMessage
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  domainsUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

export default injectIntl(DomainsTable);