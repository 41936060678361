import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./AccountsUIHelpers";

const AccountsUIContext = createContext();

export function useAccountsUIContext() {
  return useContext(AccountsUIContext);
}

export const AccountsUIConsumer = AccountsUIContext.Consumer;

export function AccountsUIProvider({accountsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initAccount = {
    id: undefined,
    method: 0,
    transfer: 0,
    details: '',
    active: 0
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initAccount,
    exportAccountsButtonClick: accountsUIEvents.exportAccountsButtonClick,
    openEditAccountDialog: accountsUIEvents.openEditAccountDialog,
    openDepositAccountDialog: accountsUIEvents.openDepositAccountDialog,
    openWithdrawAccountDialog: accountsUIEvents.openWithdrawAccountDialog
  };

  return <AccountsUIContext.Provider value={value}>{children}</AccountsUIContext.Provider>;
}