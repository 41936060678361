import React, { useRef } from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) classes.push("is-invalid");
  if (touched && !errors) classes.push("is-valid");
  return classes.join(" ");
};

export function Textarea({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = false,
  customFeedbackLabel,
  type = "text",
  ...props
}) {

  const textarea = useRef()

  const insertTab = (e) => {
      if (e.keyCode === 9) {
        e.preventDefault()
        const start = textarea.current.selectionStart
        const end = textarea.current.selectionEnd
        field.value = textarea.current.value = textarea.current.value.substring(0, start) + "\t" + textarea.current.value.substring(end)
        textarea.current.selectionStart = textarea.current.selectionEnd = start + 1
      }
  }

  return (
    <>
      {label && <label><b>{label}</b></label>}
      <textarea
        ref={textarea}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        rows={6}
        {...field}
        {...props}
        onKeyDown={(e) => insertTab(e)}
        onBlur={(e) => e.preventDefault()}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
