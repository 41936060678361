import axios from "axios";

export const POST_URL = "gateways";

// CREATE =>  POST: add a new gateway to the server
export function createGateway(gateway, domainId) {
  return axios.post(`${POST_URL}/${domainId}`, gateway);
}

// READ
// export function getAllGateways() {
//   return axios.get(POST_URL);
// }

export function getGatewayById(gatewayId, domainId) {
  return axios.get(`${POST_URL}/${gatewayId}/${domainId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findGateways(queryParams, domainId) {
  return axios.post(`${POST_URL}/find/${domainId}`, {queryParams});
}

// UPDATE => PUT: update the gateway on the server
export function updateGateway(gateway, domainId) {
  return axios.put(`${POST_URL}/${gateway.id}/${domainId}`, gateway);
}

// DELETE => delete the gateway from the server
export function deleteGateway(gatewayId, domainId) {
  return axios.delete(`${POST_URL}/${gatewayId}/${domainId}`);
}
