import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";

import {DashboardPage} from "./pages/DashboardPage";
import {AccountsPage} from "./pages/Accounts/AccountsPage";
import {TransactionsPage} from "./pages/Transactions/TransactionsPage";
import {DomainsPage} from "./pages/Domains/DomainsPage";
import DomainEdit from "./pages/Domains/domain-edit/DomainEdit";
import {UsersPage} from "./pages/Users/UsersPage";
import {SettingsPage} from "./pages/Settings/SettingsPage";

export default function BasePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/accounts" component={AccountsPage}/>
                <ContentRoute path="/transactions" component={TransactionsPage}/>
                <ContentRoute path="/domains/new" component={DomainEdit}/>
                <ContentRoute path="/domains/:id/edit" component={DomainEdit}/>
                <ContentRoute path="/domains" component={DomainsPage}/>
                <ContentRoute path="/users" component={UsersPage}/>
                <ContentRoute path="/settings" component={SettingsPage}/>
                <Redirect to="/error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
