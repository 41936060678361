import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/usersActions";
import UserEditDialogHeader from "./UserEditDialogHeader";
import UserEditForm from "./UserEditForm";
import { useUsersUIContext } from "../UsersUIContext";
import {usersSlice, callTypes} from "../_redux/usersSlice";
import * as requestFromServer from "../_redux/usersCrud";

export function UserEditDialog({ id, show, onHide }) {

  const [errors, setErrors] = useState([])
  const [current, setCurrent] = useState({})
  // Users UI Context
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      initUser: usersUIContext.initUser
    };
  }, [usersUIContext]);

  // Users Redux state
  const dispatch = useDispatch();
  const { actionsLoading, userForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.users.actionsLoading,
      userForEdit: state.users.userForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchUser(id));
    setErrors([])
  }, [id, dispatch]);

  // server request for saving user
  const saveUser = (user) => {
    if (!id) {
      // server request for creating user
      dispatch(usersSlice.actions.startCall({ callType: callTypes.action }));
      return requestFromServer
        .createUser(user)
        .then(({data}) => {
          if ('errors' in data) {
            let error = []
            data.errors.forEach(e => {
              error.push(('field' in e) ? e.field + ': ' + e.msg : e.msg)
            })
            setErrors(error)
            setCurrent(user)
            dispatch(usersSlice.actions.catchError({ error: {clientMessage: "Error Validating User"}, callType: callTypes.action }))
          } else {
            dispatch(usersSlice.actions.userCreated({ user: data }));
            onHide()
          }
        })
        .catch(error => {
          error.clientMessage = "Error Creating User"
          setErrors([error.clientMessage + ' : ' + error.message])
          setCurrent(user)
          dispatch(usersSlice.actions.catchError({ error, callType: callTypes.action }));
        })

    } else {
      // server request for updating user
      dispatch(usersSlice.actions.startCall({ callType: callTypes.action }));
      return requestFromServer
        .updateUser(user)
        .then(({data}) => {
          if ('errors' in data) {
            let error = []
            data.errors.forEach(e => {
              error.push(('field' in e) ? e.field + ': ' + e.msg : e.msg)
            })
            setErrors(error)
            setCurrent(user)
            dispatch(usersSlice.actions.catchError({ error: {clientMessage: "Error Validating User"}, callType: callTypes.action }))
          } else {
            dispatch(usersSlice.actions.userUpdated({ user }));
            onHide()
          }
        })
        .catch(error => {
          error.clientMessage = "Error Updating User"
          setErrors([error.clientMessage + ' : ' + error.message])
          setCurrent(user)
          dispatch(usersSlice.actions.catchError({ error, callType: callTypes.action }));
        });
    }
  };
  
  let i = 0;
  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <UserEditDialogHeader id={id} />
      {errors.length > 0 && (
        <div className="alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">
            <ul className="m-0">{errors.map(error => (
                <li key={i++}>{error}</li>
              ))}</ul>
          </div>
        </div>
      )}
      <UserEditForm
        saveUser={saveUser}
        actionsLoading={actionsLoading}
        user={{...usersUIProps.initUser, ...userForEdit, ...current}}
        onHide={onHide}
      />
    </Modal>
  );
}
