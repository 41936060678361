import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/gatewaysActions";
import GatewayEditDialogHeader from "./GatewayEditDialogHeader";
import GatewayEditForm from "./GatewayEditForm";
import { useGatewaysUIContext } from "../GatewaysUIContext";

export function GatewayEditDialog() {
  // Gateways UI Context
  const gatewaysUIContext = useGatewaysUIContext();
  const gatewaysUIProps = useMemo(() => {
    return {
      id: gatewaysUIContext.selectedId,
      setIds: gatewaysUIContext.setIds,
      domainId: gatewaysUIContext.domainId,
      queryParams: gatewaysUIContext.queryParams,
      showEditGatewayDialog: gatewaysUIContext.showEditGatewayDialog,
      closeEditGatewayDialog: gatewaysUIContext.closeEditGatewayDialog,
      initGateway: gatewaysUIContext.initGateway,
    };
  }, [gatewaysUIContext]);

  // Gateways Redux state
  const dispatch = useDispatch();
  const { actionsLoading, gatewayForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.gateways.actionsLoading,
      gatewayForEdit: state.gateways.gatewayForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server request for getting gateway by seleted id
    dispatch(actions.fetchGateway(gatewaysUIProps.id, gatewaysUIContext.domainId));
  }, [gatewaysUIProps.id, gatewaysUIContext.domainId, dispatch]);

  const saveGateway = (gateway) => {
    if (!gatewaysUIProps.id) {
      // server request for creating gateways
      dispatch(actions.createGateway(gateway, gatewaysUIProps.domainId)).then(() => {
        // refresh list after deletion
        dispatch(
          actions.fetchGateways(
            gatewaysUIProps.queryParams,
            gatewaysUIProps.domainId
          )
        ).then(() => {
          // clear selections list
          gatewaysUIProps.setIds([]);
          // closing edit modal
          gatewaysUIProps.closeEditGatewayDialog();
        });
      });
    } else {
      // server request for updating gateways
      dispatch(actions.updateGateway(gateway, gatewaysUIProps.domainId)).then(() => {
        // refresh list after deletion
        dispatch(
          // refresh list after deletion
          actions.fetchGateways(
            gatewaysUIProps.queryParams,
            gatewaysUIProps.domainId
          )
        ).then(() => {
          // clear selections list
          gatewaysUIProps.setIds([]);
          // closing edit modal
          gatewaysUIProps.closeEditGatewayDialog();
        });
      });
    }
  };

  return (
    <Modal
      show={gatewaysUIProps.showEditGatewayDialog}
      onHide={gatewaysUIProps.closeEditGatewayDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <GatewayEditDialogHeader id={gatewaysUIProps.id} />
      <GatewayEditForm
        saveGateway={saveGateway}
        actionsLoading={actionsLoading}
        gateway={gatewayForEdit || gatewaysUIProps.initGateway}
        onHide={gatewaysUIProps.closeEditGatewayDialog}
      />
    </Modal>
  );
}
