import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { LoadingDialog } from "../../../../_metronic/_partials/controls";

const AccountsLoadingDialog = ({ intl }) => {
  // Accounts Redux state
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.accounts.listLoading }),
    shallowEqual
  );
  // looking for loading/dispatch
  useEffect(() => {}, [isLoading]);
  return <LoadingDialog isLoading={isLoading} text={intl.formatMessage({id: 'COMMON.DIALOG.LOADING'})} />;
}

export default injectIntl(AccountsLoadingDialog);