import axios from "axios";

export const ENQUIRIES_URL = "settings";

// CREATE =>  POST: add a new setting to the server
export function createSetting(setting) {
  return axios.post(ENQUIRIES_URL, setting);
}

// READ
// export function getAllSettings() {
//   return axios.get(ENQUIRIES_URL);
// }

export function getSettingById(settingId) {
  return axios.get(`${ENQUIRIES_URL}/${settingId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSettings(queryParams) {
  return axios.post(`${ENQUIRIES_URL}/find`, { queryParams });
}

export function exportSettings(queryParams) {
  return axios.post(`${ENQUIRIES_URL}/export`, { queryParams });
}

// UPDATE => PUT: update the setting on the server
export function updateSetting(setting) {
  return axios.put(`${ENQUIRIES_URL}/${setting.id}`, setting);
}

// DELETE => delete the setting from the server
export function deleteSetting(settingId) {
  return axios.delete(`${ENQUIRIES_URL}/${settingId}`);
}
