import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";

const AccountEditDialogHeader = ({ intl, id }) =>  {
  // Accounts Redux state
  const { accountForEdit, actionsLoading } = useSelector(
    (state) => ({
      accountForEdit: state.accounts.accountForEdit,
      actionsLoading: state.accounts.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = id ? intl.formatMessage({id: 'COMMON.EDIT.TITLE'}) : intl.formatMessage({id: 'COMMON.ADD.TITLE'});
    setTitle(_title);
    // eslint-disable-next-line
  }, [accountForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}

export default injectIntl(AccountEditDialogHeader);