import axios from "axios";

export const BASE_URL = "users";

export function login(username, password) {
  return axios.post(BASE_URL+'/login', { username, password });
}

export function getUserByToken() {
  return axios.get(BASE_URL+'/profile');
}
