import React, { Component } from 'react';
import Creatable from 'react-select/creatable';

export class CreatableSelect extends Component {

  constructor(props) {
    super(props)
    let value = []
    if('defaultValues' in props && props.defaultValues) {
      props.defaultValues.forEach(val => value.push({label: val, value: val}))
    } else {
      value = []
    }
    this.state = {
      inputValue: '',
      value
    }
  }

  handleChange = (value) => {
    this.setState({ value });
  };

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };

  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.setState({
          inputValue: '',
          value: [...value, {label: inputValue, value: inputValue}],
        });
        event.preventDefault();
      break;
      default:
    }
  };

  render() {
    const { placeholder, label } = this.props;
    const { inputValue, value } = this.state;
    return (
      <>
        {label && <label><b>{label}</b></label>}
        <Creatable
          components={{DropdownIndicator: null}}
          inputValue={inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          placeholder={placeholder}
          value={value}
        />
      </>
    );
  }

}