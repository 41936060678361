import React, { useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";
import * as actions from "../_redux/usersActions";
import {useUsersUIContext} from "../UsersUIContext";

export function UsersExportDialog({ id, show, onHide }) {
  // Users UI Context
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      setIds: usersUIContext.setIds,
      queryParams: usersUIContext.queryParams
    };
  }, [usersUIContext]);

  // Users Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.users.actionsLoading }),
    shallowEqual
  );

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const exportUsers = () => {
    dispatch(actions.exportUsers(usersUIProps.queryParams)).then(() => {
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <FormattedMessage id="COMMON.EXPORT.TITLE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span><FormattedMessage id="COMMON.EXPORT.MESSAGE" /></span>
        )}
        {isLoading && <span><FormattedMessage id="COMMON.EXPORT.PROGRESS" /></span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="COMMON.DIALOG.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            onClick={exportUsers}
            className="btn btn-primary btn-elevate"
          >
            <FormattedMessage id="COMMON.EXPORT.SUBMIT" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
