// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { injectIntl } from "react-intl";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/transactionsActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  PaginationMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../TransactionsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useTransactionsUIContext } from "../TransactionsUIContext";

const TransactionsTable = ({ intl }) => {  
  // Transactions UI Context
  const transactionsUIContext = useTransactionsUIContext();
  const transactionsUIProps = useMemo(() => {
    return {
      ids: transactionsUIContext.ids,
      setIds: transactionsUIContext.setIds,
      queryParams: transactionsUIContext.queryParams,
      setQueryParams: transactionsUIContext.setQueryParams,
      openEditTransactionDialog: transactionsUIContext.openEditTransactionDialog
    };
  }, [transactionsUIContext]);

  // Getting curret state of transactions list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.transactions }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Transactions Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    transactionsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchTransactions(transactionsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      hidden: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "domainName",
      text: intl.formatMessage({id: 'COMMON.TABLE.DOMAIN'}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "source",
      text: intl.formatMessage({id: 'COMMON.TABLE.SOURCE'}),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.AccountColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "destination",
      text: intl.formatMessage({id: 'COMMON.TABLE.DESTINATION'}),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.AccountColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "amount",
      text: intl.formatMessage({id: 'COMMON.TABLE.AMOUNT'}),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.AmountColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "type",
      text: intl.formatMessage({id: 'COMMON.TABLE.TYPE'}),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.TypeColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: intl.formatMessage({id: 'COMMON.TABLE.STATUS'}),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "modified",
      text: intl.formatMessage({id: 'COMMON.TABLE.MODIFIED'}),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DateColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: intl.formatMessage({id: 'COMMON.TABLE.ACTIONS'}),
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditTransactionDialog: transactionsUIProps.openEditTransactionDialog
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: transactionsUIProps.queryParams.pageSize,
    page: transactionsUIProps.queryParams.pageNumber,
    paginationTotalRenderer: PaginationMessage
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  transactionsUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

export default injectIntl(TransactionsTable);