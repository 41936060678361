/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage } from "react-intl";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const {user} = useSelector(state => state.auth);
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
        ? " menu-item-active menu-item-open "
        : "";
  };

  return (
      <>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>

          <li
            className={`menu-item ${getMenuItemActive("/accounts")}`}
          >
            <NavLink className="menu-link" to="/accounts">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet3.svg")}/>
            </span>
              <span className="menu-text"><FormattedMessage id="MENU.ACCOUNTS" /></span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/transactions")}`}
          >
            <NavLink className="menu-link" to="/transactions">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")}/>
            </span>
              <span className="menu-text"><FormattedMessage id="MENU.TRANSACTIONS" /></span>
            </NavLink>
          </li>

          {user.role === 1 &&
          <>
            <li className="menu-section ">
              <h4 className="menu-text"><FormattedMessage id="MENU.ADMIN" /></h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>

            <li
                className={`menu-item ${getMenuItemActive("/domains")}`}
            >
              <NavLink className="menu-link" to="/domains">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}/>
              </span>
                <span className="menu-text"><FormattedMessage id="MENU.DOMAINS" /></span>
              </NavLink>
            </li>

            <li
                className={`menu-item ${getMenuItemActive("/users")}`}
            >
              <NavLink className="menu-link" to="/users">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
              </span>
                <span className="menu-text"><FormattedMessage id="MENU.USERS" /></span>
              </NavLink>
            </li>

            <li
                className={`menu-item ${getMenuItemActive("/settings")}`}
            >
              <NavLink className="menu-link" to="/settings">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}/>
              </span>
                <span className="menu-text"><FormattedMessage id="MENU.SETTINGS" /></span>
              </NavLink>
            </li>
          </>
          }

        </ul>
      </>
  );
}
