import React, { useMemo } from "react";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useTransactionsUIContext } from "../TransactionsUIContext";

const prepareFilter = (queryParams, values) => {
  const { id, type, status, domain, account, particulars } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.id = id !== "" ? id : undefined;
  filter.type = type !== "" ? type : undefined;
  filter.status = status !== "" ? status : undefined;
  filter.domain = domain !== "" ? domain : undefined;
  filter.account = account !== "" ? account : undefined;
  filter.particulars = particulars !== "" ? particulars : undefined;
  newQueryParams.filter = filter;
  return newQueryParams;
};

const TransactionsFilter = ({ intl, listLoading }) =>  {
  // Transactions UI Context
  const transactionsUIContext = useTransactionsUIContext();
  const transactionsUIProps = useMemo(() => {
    return {
      queryParams: transactionsUIContext.queryParams,
      setQueryParams: transactionsUIContext.setQueryParams,
    };
  }, [transactionsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(transactionsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, transactionsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      transactionsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          id: "",
          type: "",
          status: "",
          domain: "",
          account: "",
          particulars: ""
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="type"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("type", e.target.value);
                    handleSubmit();
                  }}
                  value={values.type}
                >
                  <option value="">{intl.formatMessage({id: 'TRANSACTIONS.TYPE.ALL'})}</option>
                  <option value="0">{intl.formatMessage({id: 'TRANSACTIONS.TYPE.TRANSFER'})}</option>
                  <option value="1">{intl.formatMessage({id: 'TRANSACTIONS.TYPE.DEPOSIT'})}</option>
                  <option value="2">{intl.formatMessage({id: 'TRANSACTIONS.TYPE.WITHDRAWAL'})}</option>
                </select>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="status"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                    handleSubmit();
                  }}
                  value={values.status}
                >
                  <option value="">{intl.formatMessage({id: 'TRANSACTIONS.STATUS.ALL'})}</option>
                  <option value="0">{intl.formatMessage({id: 'TRANSACTIONS.STATUS.PENDING'})}</option>
                  <option value="1">{intl.formatMessage({id: 'TRANSACTIONS.STATUS.SUCCESS'})}</option>
                  <option value="2">{intl.formatMessage({id: 'TRANSACTIONS.STATUS.FAILURE'})}</option>
                </select>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="id"
                  placeholder={intl.formatMessage({id: 'TRANSACTIONS.FIELD.ID'})}
                  onBlur={handleBlur}
                  value={values.id}
                  onChange={(e) => {
                    setFieldValue("id", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="domain"
                  placeholder={intl.formatMessage({id: 'COMMON.TABLE.DOMAIN'})}
                  onBlur={handleBlur}
                  value={values.domain}
                  onChange={(e) => {
                    setFieldValue("domain", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="account"
                  placeholder={intl.formatMessage({id: 'COMMON.TABLE.ACCOUNT'})}
                  onBlur={handleBlur}
                  value={values.account}
                  onChange={(e) => {
                    setFieldValue("account", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="particulars"
                  placeholder={intl.formatMessage({id: 'COMMON.TABLE.PARTICULARS'})}
                  onBlur={handleBlur}
                  value={values.particulars}
                  onChange={(e) => {
                    setFieldValue("particulars", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default injectIntl(TransactionsFilter);