/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ProfileEditForm from "./ProfileEditForm"
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_helpers";

export function QuickUser() {
  const {user} = useSelector(state => state.auth)
  const [profile, changeProfile] = useState({})
  const history = useHistory();

  useEffect(() => {
    axios.get('users/profile').then(({data}) => {
      if(!('errors' in data)) {
        changeProfile(data)
      }
    })
  }, [])
  
  const logoutClick = () => {
      const toggle = document.getElementById("kt_quick_user_toggle");
      if(toggle) toggle.click();
      history.push("/logout");
  };

  return (
      <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
        
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            <FormattedMessage id="PROFILE.TITLE" />
          </h3>
          <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
            <i className="ki ki-close icon-xs text-muted"/>
          </a>
        </div>

        <div className="offcanvas-content pr-5 mr-n5">
          <div className="d-flex align-items-center mt-5">
            <div className="symbol symbol-100 mr-5">
              <span className="symbol-label font-size-h5 font-weight-bold">
                  <SVG src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")} title="" />
              </span>
            </div>
            <div className="d-flex flex-column">
              <a href="#" className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                {user.username}
              </a>
            <div className="text-muted">{user.role === 1 ? <FormattedMessage id="PROFILE.ROLE.ADMIN" /> : <FormattedMessage id="PROFILE.ROLE.USER" />}</div>
              <button className="btn btn-light-danger btn-bold mt-2" onClick={logoutClick}><FormattedMessage id="AUTH.GENERAL.SIGNOUT_BUTTON" /></button>
            </div>
          </div>

          <div className="separator separator-dashed mt-8 mb-5"/>

          {(profile.id) ? <ProfileEditForm user={profile} /> : <></>}
          
        </div>
      </div>
  );
}
