export default function setupAxios(axios, store, lang) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();
      
      config.baseURL = (process.env.NODE_ENV === 'development') ? 'http://localhost:5000/' : 'https://dms-server.azurewebsites.net/wallet/';
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      if('selectedLang' in lang) {
        config.headers['Accept-Language'] = lang.selectedLang;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(
    response => response,
    error => {
      if(error.message === 'Request failed with status code 401') {
        window.location = '/logout';
      }
      return Promise.reject(error);
    }
  );
}