import * as requestFromServer from "./settingsCrud";
import {settingsSlice, callTypes} from "./settingsSlice";

const {actions} = settingsSlice;

export const fetchSettings = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSettings(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.settingsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find settings";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const exportSettings = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .exportSettings(queryParams)
    .then(response => {
      const file = response.data
      dispatch(actions.settingsExported({ exportedFile: file }));
    })
    .catch(error => {
      error.clientMessage = "Can't export settings";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSetting = id => dispatch => {
  if (!id) {
    return dispatch(actions.settingFetched({ settingForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSettingById(id)
    .then(response => {
      const setting = response.data;
      dispatch(actions.settingFetched({ settingForEdit: setting }));
    })
    .catch(error => {
      error.clientMessage = "Can't find setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSetting = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSetting(id)
    .then(response => {
      dispatch(actions.settingDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSetting = settingForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSetting(settingForCreation)
    .then(response => {
      const { setting } = response.data;
      dispatch(actions.settingCreated({ setting }));
    })
    .catch(error => {
      error.clientMessage = "Can't create setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSetting = setting => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSetting(setting)
    .then(() => {
      dispatch(actions.settingUpdated({ setting }));
    })
    .catch(error => {
      error.clientMessage = "Can't update setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
