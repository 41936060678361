// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  Select,
} from "../../../../_metronic/_partials/controls";

// Validation schema
const AccountEditSchema = Yup.object().shape({

});

const AccountEditForm = ({
  intl,
  saveAccount,
  account,
  actionsLoading,
  onHide,
}) => {

  return (
    <>
      {actionsLoading ? (
        <Modal.Body className="overlay overlay-block">
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        </Modal.Body>
      ) : (
        <Formik
        enableReinitialize={true}
        initialValues={account}
        validationSchema={AccountEditSchema}
        onSubmit={(values) => {
          saveAccount(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay">
              <Form className="form form-label-right">
              <div className="form-group row">
                  <div className="col-lg-12">
                    <Select name="active" label={intl.formatMessage({id: 'ACCOUNTS.FIELD.TYPE'})}>
                      <option value="0">{intl.formatMessage({id: 'ACCOUNTS.TYPE.INACTIVE'})}</option>
                      <option value="1">{intl.formatMessage({id: 'ACCOUNTS.TYPE.ACTIVE'})}</option>
                    </Select>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="COMMON.DIALOG.CANCEL" />
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                <FormattedMessage id="COMMON.DIALOG.SAVE" />
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
      )}
    </>
  );
}

export default injectIntl(AccountEditForm);