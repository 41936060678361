// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import {
  Input,
  Select,
  CreatableSelect
} from "../../../../_metronic/_partials/controls";

// Validation schema
const DomainEditSchema = Yup.object().shape({

});

const DomainEditForm = ({
  intl,
  saveDomain,
  domain,
  actionsLoading
}) => {

  const ipsRef = React.createRef();
  const subdomainsRef = React.createRef();
  const entitiesRef = React.createRef();

  return (
    <>
      {actionsLoading ? (
        <Modal.Body className="overlay overlay-block">
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        </Modal.Body>
      ) : (
        <Formik
        enableReinitialize={true}
        initialValues={domain}
        validationSchema={DomainEditSchema}
        onSubmit={(values) => {
          let ips = []
          if(Array.isArray(ipsRef.current.state.value)) {
            ips = ipsRef.current.state.value.map(val => (val.value))
          }
          let  subdomains = []
          if(Array.isArray(subdomainsRef.current.state.value)) {
            subdomains = subdomainsRef.current.state.value.map(val => (val.value))
          }
          let entities = []
          if(Array.isArray(entitiesRef.current.state.value)) {
            entities = entitiesRef.current.state.value.map(val => (val.value))
          }
          saveDomain({...values, ips, subdomains, entities})
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay">
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Field
                      name="name"
                      label={intl.formatMessage({id: 'DOMAINS.FIELD.NAME'})}
                      component={Input}
                      disabled={domain.id ? true : false}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Select name="currency" label={intl.formatMessage({id: 'DOMAINS.FIELD.CURRENCY'})} disabled={domain.id ? true : false}>
                      <option value="GBP">GBP</option>
                      <option value="EUR">EUR</option>
                    </Select>
                  </div>
                  <div className="col-lg-4">
                    <Select name="active" label={intl.formatMessage({id: 'DOMAINS.FIELD.TYPE'})}>
                      <option value="0">{intl.formatMessage({id: 'DOMAINS.TYPE.INACTIVE'})}</option>
                      <option value="1">{intl.formatMessage({id: 'DOMAINS.TYPE.ACTIVE'})}</option>
                    </Select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">
                    <CreatableSelect
                      name="ips"
                      ref={ipsRef}
                      defaultValues={domain.ips}
                      label={intl.formatMessage({id: 'DOMAINS.FIELD.IPS'})}
                    />
                  </div>
                  <div className="col-lg-4">
                    <CreatableSelect
                      name="subdomains"
                      ref={subdomainsRef}
                      defaultValues={domain.subdomains}
                      label={intl.formatMessage({id: 'DOMAINS.FIELD.SUBDOMAINS'})}
                    />
                  </div>
                  <div className="col-lg-4">
                    <CreatableSelect
                      name="entities"
                      ref={entitiesRef}
                      defaultValues={domain.entities}
                      label={intl.formatMessage({id: 'DOMAINS.FIELD.ENTITIES'})}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                <FormattedMessage id="COMMON.DIALOG.SAVE" />
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
      )}
    </>
  );
}

export default injectIntl(DomainEditForm);