import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const ProfileEditForm = ({intl, user}) => {

  const initialValues = {...user, newPass: '', oldPass: ''}

  const ProfileEditSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .required("Cannot be empty"),
    phone: Yup.string()
      .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, "Wrong phone format")
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) return "is-invalid";
    if (formik.touched[fieldname] && !formik.errors[fieldname]) return "is-valid";
    return "";
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ProfileEditSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      axios.put('users/profile', values).then(({data}) => {
        setSubmitting(false);
        if ('errors' in data) {
          const errors = data.errors.map(error => error = ('field' in error) ? error.field.toUpperCase() + ': ' + error.msg : error.msg)
          setStatus(errors.join(', '));
        } else {
          setStatus('');
        }
      }).catch(error => {
        setSubmitting(false)
        setStatus(error.message);
      })
    },
  });

  return (
    <>
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >

        {/* begin: Alert */}
        {formik.status && (
          <div className="my-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({id: 'PROFILE.FIELD.EMAIL'})}
            type="email"
            className={`form-control form-control-solid h-auto ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Phone */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({id: 'PROFILE.FIELD.PHONE'})}
            type="text"
            className={`form-control form-control-solid h-auto ${getInputClasses(
              "phone"
            )}`}
            name="phone"
            {...formik.getFieldProps("phone")}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phone}</div>
            </div>
          ) : null}
        </div>
        {/* end: Phone */}

        {/* begin: Old Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({id: 'PROFILE.FIELD.OLDPASSWORD'})}
            type="password"
            className={`form-control form-control-solid h-auto ${getInputClasses(
              "oldPass"
            )}`}
            name="oldPass"
            {...formik.getFieldProps("oldPass")}
          />
          {formik.touched.oldPass && formik.errors.oldPass ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.oldPass}</div>
            </div>
          ) : null}
        </div>
        {/* end: Old Password */}

        {/* begin: New Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({id: 'PROFILE.FIELD.NEWPASSWORD'})}
            type="password"
            className={`form-control form-control-solid h-auto ${getInputClasses(
              "newPass"
            )}`}
            name="newPass"
            {...formik.getFieldProps("newPass")}
          />
          {formik.touched.newPass && formik.errors.newPass ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.newPass}</div>
            </div>
          ) : null}
        </div>
        {/* end: New Password */}

        <div className="form-group d-flex flex-wrap flex-center mb-0">
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="btn btn-light-warning btn-bold w-100"
          >
            <span><FormattedMessage id="COMMON.DIALOG.UPDATE" /></span>
          </button>

        </div>

      </form>
    </>
  );
}

export default injectIntl(ProfileEditForm);