import {createSlice} from "@reduxjs/toolkit";

const initialTransactionsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  transactionForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState: initialTransactionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getTransactionById
    transactionFetched: (state, action) => {
      state.actionsLoading = false;
      state.transactionForEdit = action.payload.transactionForEdit;
      state.error = null;
    },
    // exportTransactions
    transactionsExported: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.exportedFile = action.payload.exportedFile;
      state.error = null;
    },
    // findTransactions
    transactionsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createTransaction
    transactionCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.transaction);
    },
    // updateTransaction
    transactionUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.transaction.id) {
          return action.payload.transaction;
        }
        return entity;
      });
    },
    // deleteTransaction
    transactionDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    }
  }
});
