import axios from "axios";

export const ENQUIRIES_URL = "users";

// CREATE =>  POST: add a new user to the server
export function createUser(user) {
  return axios.post(ENQUIRIES_URL, user);
}

// READ
// export function getAllUsers() {
//   return axios.get(ENQUIRIES_URL);
// }

export function getUserById(userId) {
  return axios.get(`${ENQUIRIES_URL}/${userId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findUsers(queryParams) {
  return axios.post(`${ENQUIRIES_URL}/index`, { queryParams });
}

export function exportUsers(queryParams) {
  return axios.post(`${ENQUIRIES_URL}/export`, { queryParams });
}

// UPDATE => PUT: update the user on the server
export function updateUser(user) {
  return axios.put(`${ENQUIRIES_URL}/${user._id}`, user);
}

// DELETE => delete the user from the server
export function deleteUser(userId) {
  return axios.delete(`${ENQUIRIES_URL}/${userId}`);
}
