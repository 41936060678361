import React from "react";
import { Route } from "react-router-dom";
import TransactionsLoadingDialog from "./transactions-loading-dialog/TransactionsLoadingDialog";
import { TransactionEditDialog } from "./transaction-edit-dialog/TransactionEditDialog";
import { TransactionsExportDialog } from "./transactions-export-dialog/TransactionsExportDialog";
import { TransactionsUIProvider } from "./TransactionsUIContext";
import { TransactionsCard } from "./TransactionsCard";

export function TransactionsPage({ history }) {
  const transactionsUIEvents = {
    exportTransactionsButtonClick: () => {
      history.push("/transactions/export");
    },
    openEditTransactionDialog: (id) => {
      history.push(`/transactions/${id}/edit`);
    }
  }

  return (
    <TransactionsUIProvider transactionsUIEvents={transactionsUIEvents}>
      <TransactionsLoadingDialog />
      <Route path="/transactions/export">
        {({ history, match }) => (
          <TransactionsExportDialog
            show={match != null}
            onHide={() => {
              history.push("/transactions");
            }}
          />
        )}
      </Route>
      <Route path="/transactions/:id/edit">
        {({ history, match }) => (
          <TransactionEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/transactions");
            }}
          />
        )}
      </Route>
      <TransactionsCard />
    </TransactionsUIProvider>
  );
}