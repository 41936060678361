import axios from "axios";

export const POST_URL = "domains";

// CREATE =>  POST: add a new domain to the server
export function createDomain(domain) {
  return axios.post(POST_URL, domain);
}

// READ
// export function getAllDomains() {
//   return axios.get(POST_URL);
// }

export function getDomainById(domainId) {
  return axios.get(`${POST_URL}/${domainId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDomains(queryParams) {
  return axios.post(`${POST_URL}/index`, { queryParams });
}

export function exportDomains(queryParams) {
  return axios.post(`${POST_URL}/export`, { queryParams });
}

// UPDATE => PUT: update the domain on the server
export function updateDomain(domain) {
  return axios.put(`${POST_URL}/${domain.id}`, domain);
}

// DELETE => delete the domain from the server
export function deleteDomain(domainId) {
  return axios.delete(`${POST_URL}/${domainId}`);
}
