import React, { useEffect, useContext, createContext, useState, useCallback } from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./GatewaysUIHelper";

const GatewaysUIContext = createContext();

export function useGatewaysUIContext() {
  return useContext(GatewaysUIContext);
}

export const GatewaysUIConsumer = GatewaysUIContext.Consumer;

export function GatewaysUIProvider({ currentDomainId, children }) {
  const [domainId, setDomainId] = useState(currentDomainId);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const [showEditGatewayDialog, setShowEditGatewayDialog] = useState(false);
  const initGateway = {
    id: undefined,
    name: '',
    component: '',
    responseUrl: '',
    settings: '',
    active: 0
  };
  useEffect(()=> {
    initGateway.domainId = currentDomainId;
    setDomainId(currentDomainId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDomainId]);
  const openNewGatewayDialog = () => {
    setSelectedId(undefined);
    setShowEditGatewayDialog(true);
  };
  const openEditGatewayDialog = id => {
    setSelectedId(id);
    setShowEditGatewayDialog(true);
  };
  const closeEditGatewayDialog = () => {
    setSelectedId(undefined);
    setShowEditGatewayDialog(false);
  };
  const [showDeleteGatewayDialog, setShowDeleteGatewayDialog] = useState(false);
  const openDeleteGatewayDialog = id => {
    setSelectedId(id);
    setShowDeleteGatewayDialog(true);
  };
  const closeDeleteGatewayDialog = () => {
    setSelectedId(undefined);
    setShowDeleteGatewayDialog(false);
  };

  const value = {
    ids,
    setIds,
    domainId,
    setDomainId,
    queryParams,
    setQueryParams,
    initGateway,
    selectedId,
    showEditGatewayDialog,
    openNewGatewayDialog,    
    openEditGatewayDialog,
    closeEditGatewayDialog,
    showDeleteGatewayDialog,
    openDeleteGatewayDialog,
    closeDeleteGatewayDialog
  };
  
  return (
    <GatewaysUIContext.Provider value={value}>
      {children}
    </GatewaysUIContext.Provider>
  );
}
