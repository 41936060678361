/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";

const GatewayEditDialogHeader = ({ intl, id }) =>  {
  const [title, setTitle] = useState("");
  // Gateways Redux state
  const { gatewayForEdit, actionsLoading } = useSelector(
    (state) => ({
      gatewayForEdit: state.gateways.gatewayForEdit,
      actionsLoading: state.gateways.actionsLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let _title = id ? intl.formatMessage({id: 'COMMON.EDIT.TITLE'}) : intl.formatMessage({id: 'COMMON.ADD.TITLE'});

    setTitle(_title);
    // eslint-disable-next-line
  }, [gatewayForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}

export default injectIntl(GatewayEditDialogHeader);