// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { injectIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as actions from "./_redux/gatewaysActions";
import * as uiHelpers from "./GatewaysUIHelper";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  PaginationMessage,
} from "../../../../_metronic/_helpers";
import { useGatewaysUIContext } from "./GatewaysUIContext";

const GatewaysTable = ({ intl }) => {
  // Gateways UI Context
  const gatewaysUIContext = useGatewaysUIContext();
  const gatewaysUIProps = useMemo(() => {
    return {
      ids: gatewaysUIContext.ids,
      setIds: gatewaysUIContext.setIds,
      queryParams: gatewaysUIContext.queryParams,
      setQueryParams: gatewaysUIContext.setQueryParams,
      domainId: gatewaysUIContext.domainId,
      openEditGatewayDialog: gatewaysUIContext.openEditGatewayDialog,
      openDeleteGatewayDialog: gatewaysUIContext.openDeleteGatewayDialog,
    };
  }, [gatewaysUIContext]);

  // Getting curret state of domains list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.gateways }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  const dispatch = useDispatch();
  useEffect(() => {
    gatewaysUIProps.setIds([]);
    dispatch(
      actions.fetchGateways(gatewaysUIProps.queryParams, gatewaysUIProps.domainId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewaysUIProps.queryParams, dispatch, gatewaysUIProps.domainId]);
  const columns = [
    {
      dataField: "id",
      hidden: true
    },
    {
      dataField: "name",
      text: intl.formatMessage({id: 'COMMON.TABLE.NAME'}),
      sort: false
    },
    {
      dataField: "component",
      text: intl.formatMessage({id: 'COMMON.TABLE.COMPONENT'}),
      sort: false,
    },
    {
      dataField: "active",
      text: intl.formatMessage({id: 'COMMON.TABLE.ACTIVE'}),
      formatter: columnFormatters.ActiveColumnFormatter
    },
    {
      dataField: "modified",
      text: intl.formatMessage({id: 'COMMON.TABLE.MODIFIED'}),
      formatter: columnFormatters.DateColumnFormatter
    },
    {
      dataField: "action",
      text: intl.formatMessage({id: 'COMMON.TABLE.ACTIONS'}),
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditGatewayDialog: gatewaysUIProps.openEditGatewayDialog,
        openDeleteGatewayDialog: gatewaysUIProps.openDeleteGatewayDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: gatewaysUIProps.queryParams.pageSize,
    page: gatewaysUIProps.queryParams.pageNumber,
    paginationTotalRenderer: PaginationMessage
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bordered={false}
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  gatewaysUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

export default injectIntl(GatewaysTable);