import React from "react";
import { Route } from "react-router-dom";
import SettingsLoadingDialog from "./settings-loading-dialog/SettingsLoadingDialog";
import { SettingEditDialog } from "./setting-edit-dialog/SettingEditDialog";
import { SettingsUIProvider } from "./SettingsUIContext";
import { SettingsCard } from "./SettingsCard";

export function SettingsPage({ history }) {
  const settingsUIEvents = {
    openEditSettingDialog: (id) => {
      history.push(`/settings/${id}/edit`);
    }
  }

  return (
    <SettingsUIProvider settingsUIEvents={settingsUIEvents}>
      <SettingsLoadingDialog />
      <Route path="/settings/:id/edit">
        {({ history, match }) => (
          <SettingEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/settings");
            }}
          />
        )}
      </Route>
      <SettingsCard />
    </SettingsUIProvider>
  );
}