export const TransactionTypeCssClasses = ["info", "info", "info", ""];
export const TransactionTypeTitles = ["TRANSFER", "DEPOSIT", "WITHDRAWAL", ""];
export const TransactionStatusCssClasses = ["warning", "success", "danger", ""];
export const TransactionStatusTitles = ["PENDING", "SUCCESS", "FAILURE", ""];
export const defaultSorted = [{ dataField: "modified", order: "desc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  filter: {},
  sortOrder: "desc", // asc||desc
  sortField: "modified",
  pageNumber: 1,
  pageSize: 10
};