import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import UsersFilter from "./users-filter/UsersFilter";
import UsersTable from "./users-table/UsersTable";
import { useUsersUIContext } from "./UsersUIContext";

export function UsersCard() {
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      ids: usersUIContext.ids,
      newUserButtonClick: usersUIContext.newUserButtonClick
    };
  }, [usersUIContext]);

  return (
    <Card>
      <CardHeader title={<FormattedMessage id="USERS.TITLE" />}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={usersUIProps.newUserButtonClick}
          >
            <FormattedMessage id="COMMON.TABLE.ADD" />
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <UsersFilter />
        <UsersTable />
      </CardBody>
    </Card>
  );
}
