export const UserActiveCssClasses = ["danger", "success", ""];
export const UserActiveTitles = ["INACTIVE", "ACTIVE", ""];
export const UserRoleCssClasses = ["warning", "success", ""];
export const UserRoleTitles = ["USER", "ADMIN", ""];
export const defaultSorted = [{ dataField: "modified", order: "desc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  filter: {},
  sortOrder: "desc", // asc||desc
  sortField: "modified",
  pageNumber: 1,
  pageSize: 10
};