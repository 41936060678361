// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditUserDialog, openDeleteUserDialog }
) {
  return (
    <>

      <OverlayTrigger overlay={<Tooltip><FormattedMessage id="COMMON.TABLE.EDIT" /></Tooltip>}>
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
          onClick={() => openEditUserDialog(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} title=" " />
          </span>
        </a>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip><FormattedMessage id="COMMON.TABLE.DELETE" /></Tooltip>}>
        <a
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => openDeleteUserDialog(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} title=" " />
          </span>
        </a>
      </OverlayTrigger>

    </>
  );
}
