import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/transactionsActions";
import TransactionEditDialogHeader from "./TransactionEditDialogHeader";
import TransactionEditForm from "./TransactionEditForm";
import { useTransactionsUIContext } from "../TransactionsUIContext";
import {transactionsSlice, callTypes} from "../_redux/transactionsSlice";
import * as requestFromServer from "../_redux/transactionsCrud";

export function TransactionEditDialog({ id, show, onHide }) {

  const [errors, setErrors] = useState([])
  const [current, setCurrent] = useState({})
  // Transactions UI Context
  const transactionsUIContext = useTransactionsUIContext();
  const transactionsUIProps = useMemo(() => {
    return {
      initTransaction: transactionsUIContext.initTransaction
    };
  }, [transactionsUIContext]);

  // Transactions Redux state
  const dispatch = useDispatch();
  const { actionsLoading, transactionForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.transactions.actionsLoading,
      transactionForEdit: state.transactions.transactionForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchTransaction(id));
    setErrors([])
  }, [id, dispatch]);

  // server request for saving transaction
  const saveTransaction = (transaction) => {
    if (!id) {
      // server request for creating transaction
      dispatch(transactionsSlice.actions.startCall({ callType: callTypes.action }));
      return requestFromServer
        .createTransaction(transaction)
        .then(({data}) => {
          if ('errors' in data) {
            let error = []
            data.errors.forEach(e => {
              error.push(('field' in e) ? e.field + ': ' + e.msg : e.msg)
            })
            setErrors(error)
            setCurrent(transaction)
            dispatch(transactionsSlice.actions.catchError({ error: {clientMessage: "Error Validating Transaction"}, callType: callTypes.action }))
          } else {
            dispatch(transactionsSlice.actions.transactionCreated({ transaction: data }));
            onHide()
          }
        })
        .catch(error => {
          error.clientMessage = "Error Creating Transaction"
          setErrors([error.clientMessage + ' : ' + error.message])
          setCurrent(transaction)
          dispatch(transactionsSlice.actions.catchError({ error, callType: callTypes.action }));
        })

    } else {
      // server request for updating transaction
      dispatch(transactionsSlice.actions.startCall({ callType: callTypes.action }));
      return requestFromServer
        .updateTransaction(transaction)
        .then(({data}) => {
          if ('errors' in data) {
            let error = []
            data.errors.forEach(e => {
              error.push(('field' in e) ? e.field + ': ' + e.msg : e.msg)
            })
            setErrors(error)
            setCurrent(transaction)
            dispatch(transactionsSlice.actions.catchError({ error: {clientMessage: "Error Validating Transaction"}, callType: callTypes.action }))
          } else {
            dispatch(transactionsSlice.actions.transactionUpdated({ transaction }));
            onHide()
          }
        })
        .catch(error => {
          error.clientMessage = "Error Updating Transaction"
          setErrors([error.clientMessage + ' : ' + error.message])
          setCurrent(transaction)
          dispatch(transactionsSlice.actions.catchError({ error, callType: callTypes.action }));
        });
    }
  };
  
  let i = 0;
  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <TransactionEditDialogHeader id={id} />
      {errors.length > 0 && (
        <div className="alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">
            <ul className="m-0">{errors.map(error => (
                <li key={i++}>{error}</li>
              ))}</ul>
          </div>
        </div>
      )}
      <TransactionEditForm
        saveTransaction={saveTransaction}
        actionsLoading={actionsLoading}
        transaction={{...transactionsUIProps.initTransaction, ...transactionForEdit, ...current}}
        onHide={onHide}
      />
    </Modal>
  );
}
