// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  Textarea
} from "../../../../_metronic/_partials/controls";

// Validation schema
const AccountDepositSchema = Yup.object().shape({

});

const AccountDepositForm = ({
  intl,
  deposit,
  account,
  actionsLoading,
  onHide,
}) => {

  return (
    <>
      {actionsLoading ? (
        <Modal.Body className="overlay overlay-block">
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        </Modal.Body>
      ) : (
        <Formik
        enableReinitialize={true}
        initialValues={account}
        validationSchema={AccountDepositSchema}
        onSubmit={(values) => {
          deposit(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay">
              <Form className="form form-label-right">
                <div className="row">
                  <div className="form-group col-lg-12">
                    <Field
                      name="transfer"
                      component={Input}
                      label={intl.formatMessage({id: 'ACCOUNTS.FIELD.AMOUNT'})}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-lg-12">
                    <Select name="method" label={intl.formatMessage({id: 'ACCOUNTS.FIELD.METHOD'})}>
                      <option value="0">{intl.formatMessage({id: 'ACCOUNTS.METHOD.OFFLINE'})}</option>
                      <option value="1">{intl.formatMessage({id: 'ACCOUNTS.METHOD.ONLINE'})}</option>
                    </Select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-lg-12">
                    <Field
                      name="particulars"
                      component={Input}
                      label={intl.formatMessage({id: 'ACCOUNTS.FIELD.PARTICULARS'})}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-lg-12">
                    <Field
                      name="details"
                      component={Textarea}
                      label={intl.formatMessage({id: 'ACCOUNTS.FIELD.DETAILS'})}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="COMMON.DIALOG.CANCEL" />
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                <FormattedMessage id="COMMON.DIALOG.SAVE" />
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
      )}
    </>
  );
}

export default injectIntl(AccountDepositForm);