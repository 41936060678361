import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/accountsActions";
import AccountEditDialogHeader from "./AccountEditDialogHeader";
import AccountEditForm from "./AccountEditForm";
import { useAccountsUIContext } from "../AccountsUIContext";
import {accountsSlice, callTypes} from "../_redux/accountsSlice";
import * as requestFromServer from "../_redux/accountsCrud";

export function AccountEditDialog({ id, show, onHide }) {

  const [errors, setErrors] = useState([])
  const [current, setCurrent] = useState({})
  // Accounts UI Context
  const accountsUIContext = useAccountsUIContext();
  const accountsUIProps = useMemo(() => {
    return {
      initAccount: accountsUIContext.initAccount
    };
  }, [accountsUIContext]);

  // Accounts Redux state
  const dispatch = useDispatch();
  const { actionsLoading, accountForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.accounts.actionsLoading,
      accountForEdit: state.accounts.accountForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchAccount(id));
    setErrors([])
  }, [id, dispatch]);

  // server request for saving account
  const saveAccount = (account) => {
    if (!id) {
      // server request for creating account
      dispatch(accountsSlice.actions.startCall({ callType: callTypes.action }));
      return requestFromServer
        .createAccount(account)
        .then(({data}) => {
          if ('errors' in data) {
            let error = []
            data.errors.forEach(e => {
              error.push(('field' in e) ? e.field + ': ' + e.msg : e.msg)
            })
            setErrors(error)
            setCurrent(account)
            dispatch(accountsSlice.actions.catchError({ error: {clientMessage: "Error Validating Account"}, callType: callTypes.action }))
          } else {
            dispatch(accountsSlice.actions.accountCreated({ account: data }));
            onHide()
          }
        })
        .catch(error => {
          error.clientMessage = "Error Creating Account"
          setErrors([error.clientMessage + ' : ' + error.message])
          setCurrent(account)
          dispatch(accountsSlice.actions.catchError({ error, callType: callTypes.action }));
        })

    } else {
      // server request for updating account
      dispatch(accountsSlice.actions.startCall({ callType: callTypes.action }));
      return requestFromServer
        .updateAccount(account)
        .then(({data}) => {
          if ('errors' in data) {
            let error = []
            data.errors.forEach(e => {
              error.push(('field' in e) ? e.field + ': ' + e.msg : e.msg)
            })
            setErrors(error)
            setCurrent(account)
            dispatch(accountsSlice.actions.catchError({ error: {clientMessage: "Error Validating Account"}, callType: callTypes.action }))
          } else {
            dispatch(accountsSlice.actions.accountUpdated({ account }));
            onHide()
          }
        })
        .catch(error => {
          error.clientMessage = "Error Updating Account"
          setErrors([error.clientMessage + ' : ' + error.message])
          setCurrent(account)
          dispatch(accountsSlice.actions.catchError({ error, callType: callTypes.action }));
        });
    }
  };
  
  let i = 0;
  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <AccountEditDialogHeader id={id} />
      {errors.length > 0 && (
        <div className="alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">
            <ul className="m-0">{errors.map(error => (
                <li key={i++}>{error}</li>
              ))}</ul>
          </div>
        </div>
      )}
      <AccountEditForm
        saveAccount={saveAccount}
        actionsLoading={actionsLoading}
        account={{...accountsUIProps.initAccount, ...accountForEdit, ...current}}
        onHide={onHide}
      />
    </Modal>
  );
}
