import axios from "axios";

export const BASE_URL = "accounts";

// CREATE =>  POST: add a new account to the server
export function createAccount(account) {
  return axios.post(BASE_URL, account);
}

// READ
// export function getAllAccounts() {
//   return axios.get(BASE_URL);
// }

export function getAccountById(accountId) {
  return axios.get(`${BASE_URL}/${accountId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findAccounts(queryParams) {
  return axios.post(`${BASE_URL}/index`, { queryParams });
}

export function exportAccounts(queryParams) {
  return axios.post(`${BASE_URL}/export`, { queryParams });
}

// UPDATE => PUT: update the account on the server
export function updateAccount(account) {
  return axios.put(`${BASE_URL}/${account._id}`, account);
}

// UPDATE => PUT: update the account on the server
export function deposit(account) {
  return axios.post(`${BASE_URL}/deposit/${account._id}`, account);
}

export function withdraw(account) {
  return axios.post(`${BASE_URL}/withdraw/${account._id}`, account);
}

// DELETE => delete the account from the server
export function deleteAccount(accountId) {
  return axios.delete(`${BASE_URL}/${accountId}`);
}
