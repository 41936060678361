import {createSlice} from "@reduxjs/toolkit";

const initialDomainsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  domainForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const domainsSlice = createSlice({
  name: "domains",
  initialState: initialDomainsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getDomainById
    domainFetched: (state, action) => {
      state.actionsLoading = false;
      state.domainForEdit = action.payload.domainForEdit;
      state.error = null;
    },
    // exportDomains
    domainsExported: (state, action) => {
      state.actionsLoading = false;
      state.exportedFile = action.payload.exportedFile;
      state.error = null;
    },
    // findDomains
    domainsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createDomain
    domainCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.domain);
    },
    // updateDomain
    domainUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.domain.id) {
          return action.payload.domain;
        }
        return entity;
      });
    },
    // deleteDomain
    domainDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    }
  }
});
