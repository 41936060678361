// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {
  TransactionTypeCssClasses,
  TransactionTypeTitles,
} from "../../TransactionsUIHelpers";

export function TypeColumnFormatter(value, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${
      TransactionTypeCssClasses[value]
    } label-inline`;
  };
  return (
    <>
      {
        (row.particulars) ?
          <OverlayTrigger overlay={<Tooltip>{row.particulars}</Tooltip>}>
            <span className={getLabelCssClasses()+' cursor-pointer'}>
              {<FormattedMessage id={"TRANSACTIONS.TYPE."+TransactionTypeTitles[value]} />}
            </span>
          </OverlayTrigger>
        :
          <span className={getLabelCssClasses()}>
            {<FormattedMessage id={"TRANSACTIONS.TYPE."+TransactionTypeTitles[value]} />}
          </span>
      }
    </>
  );
}
