import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import noop from 'lodash.noop';
import axios from 'axios';

const propTypes = {
  searchApiUrl: PropTypes.string.isRequired,
  limit: PropTypes.number,
  defaultValue: PropTypes.object,
  actionOnSelectedOption: PropTypes.func
};

const defaultProps = {
  limit: 10,
  defaultValue: null,
  actionOnSelectedOption: noop
};

export class SearchableSelect extends Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      searchApiUrl: props.searchApiUrl,
      limit: props.limit,
      selectedOption: this.props.defaultValue,
      actionOnSelectedOption: props.actionOnSelectedOption 
    };
    this.getOptions = this.getOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  getOptionValue = (option) => option.id; // maps the result 'id' as the 'value'
  getOptionLabel = (option) => option.name; // maps the result 'name' as the 'label'

  handleChange(selectedOption, {action}) { // you can use the 'action' to do different things here
    this.setState({
      selectedOption: selectedOption
    });
    // this is for update action on selectedOption
    // will use the noop defaultProp if the dev didn't define the prop, so no need to conditionally call
    this.state.actionOnSelectedOption(selectedOption.value);
  }

  // async/await returns a Promise, so use the Promise form as seen in the
  // documentation https://react-select.com/async
  async getOptions(inputValue) {
    const response = await axios.get(`${this.state.searchApiUrl}?search=${inputValue}&limit=${this.state.limit}`);
    return response.data;
  }

  // inputValue state is controlled in the Select, so this probably isn't necessary
  // except to maybe validate that it is changing
  handleInputChange(inputValue) {
    this.setState({ inputValue });
    return inputValue;
  }

  render() {
    const { defaultOptions, placeholder } = this.props;
    const { selectedOption } = this.state;
    const label = this.props.label
    return (
      <>
        {label && <label><b>{label}</b></label>}
        <AsyncSelect
          cacheOptions
          value={selectedOption}
          getOptionValue={this.getOptionValue}
          getOptionLabel={this.getOptionLabel}
          defaultOptions={defaultOptions}
          loadOptions={this.getOptions}
          placeholder={placeholder}
          onChange={this.handleChange}
        />
      </>
    );
  }
}