import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/accountsActions";
import AccountWithdrawDialogHeader from "./AccountWithdrawDialogHeader";
import AccountWithdrawForm from "./AccountWithdrawForm";
import { useAccountsUIContext } from "../AccountsUIContext";
import {accountsSlice, callTypes} from "../_redux/accountsSlice";
import * as requestFromServer from "../_redux/accountsCrud";

export function AccountWithdrawDialog({ id, show, onHide }) {

  const [errors, setErrors] = useState([])
  const [current, setCurrent] = useState({})
  // Accounts UI Context
  const accountsUIContext = useAccountsUIContext();
  const accountsUIProps = useMemo(() => {
    return {
      initAccount: accountsUIContext.initAccount
    };
  }, [accountsUIContext]);

  // Accounts Redux state
  const dispatch = useDispatch();
  const { actionsLoading, accountForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.accounts.actionsLoading,
      accountForEdit: state.accounts.accountForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchAccount(id));
    setErrors([])
  }, [id, dispatch]);

  // server request for saving account
  const withdraw = (account) => {
      // server request for updating account
      dispatch(accountsSlice.actions.startCall({ callType: callTypes.action }));
      return requestFromServer
        .withdraw(account)
        .then(({data}) => {
          if ('errors' in data) {
            let error = []
            data.errors.forEach(e => {
              error.push(('field' in e) ? e.field + ': ' + e.msg : e.msg)
            })
            setErrors(error)
            setCurrent(account)
            dispatch(accountsSlice.actions.catchError({ error: {clientMessage: "Error Validating Account"}, callType: callTypes.action }))
          } else {
            dispatch(accountsSlice.actions.accountUpdated({ account: data }));
            onHide()
          }
        })
        .catch(error => {
          error.clientMessage = "Error Updating Account"
          setErrors([error.clientMessage + ' : ' + error.message])
          setCurrent(account)
          dispatch(accountsSlice.actions.catchError({ error, callType: callTypes.action }));
        });
  };
  
  let i = 0;
  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-md"
    >
      <AccountWithdrawDialogHeader id={id} />
      {errors.length > 0 && (
        <div className="alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">
            <ul className="m-0">{errors.map(error => (
                <li key={i++}>{error}</li>
              ))}</ul>
          </div>
        </div>
      )}
      <AccountWithdrawForm
        withdraw={withdraw}
        actionsLoading={actionsLoading}
        account={{...accountsUIProps.initAccount, ...accountForEdit, ...current}}
        onHide={onHide}
      />
    </Modal>
  );
}
