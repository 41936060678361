import download from 'react-file-download'

import * as requestFromServer from "./transactionsCrud";
import {transactionsSlice, callTypes} from "./transactionsSlice";

const {actions} = transactionsSlice;

export const fetchTransactions = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findTransactions(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.transactionsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const exportTransactions = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .exportTransactions(queryParams)
    .then(response => {
      const file = response.data
      download(file, "transactions_export.csv")
      dispatch(actions.transactionsExported({ exportedFile: file }));
    })
    .catch(error => {
      error.clientMessage = "Can't export transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTransaction = id => dispatch => {
  if (!id) {
    return dispatch(actions.transactionFetched({ transactionForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTransactionById(id)
    .then(response => {
      const transaction = response.data;
      dispatch(actions.transactionFetched({ transactionForEdit: transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't find transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTransaction = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTransaction(id)
    .then(response => {
      dispatch(actions.transactionDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createTransaction = transactionForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createTransaction(transactionForCreation)
    .then(response => {
      const { transaction } = response.data;
      dispatch(actions.transactionCreated({ transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't create transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTransaction = transaction => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTransaction(transaction)
    .then(() => {
      dispatch(actions.transactionUpdated({ transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't update transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
