import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/settingsActions";
import SettingEditDialogHeader from "./SettingEditDialogHeader";
import SettingEditForm from "./SettingEditForm";
import { useSettingsUIContext } from "../SettingsUIContext";
import {settingsSlice, callTypes} from "../_redux/settingsSlice";
import * as requestFromServer from "../_redux/settingsCrud";

export function SettingEditDialog({ id, show, onHide }) {

  const [errors, setErrors] = useState([])
  const [current, setCurrent] = useState({})
  // Settings UI Context
  const settingsUIContext = useSettingsUIContext();
  const settingsUIProps = useMemo(() => {
    return {
      initSetting: settingsUIContext.initSetting
    };
  }, [settingsUIContext]);

  // Settings Redux state
  const dispatch = useDispatch();
  const { actionsLoading, settingForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.settings.actionsLoading,
      settingForEdit: state.settings.settingForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchSetting(id));
    setErrors([])
  }, [id, dispatch]);

  // server request for saving setting
  const saveSetting = (setting) => {
    if (!id) {
      // server request for creating setting
      dispatch(settingsSlice.actions.startCall({ callType: callTypes.action }));
      return requestFromServer
        .createSetting(setting)
        .then(({data}) => {
          if ('errors' in data) {
            let error = []
            data.errors.forEach(e => {
              error.push(('field' in e) ? e.field + ': ' + e.msg : e.msg)
            })
            setErrors(error)
            setCurrent(setting)
            dispatch(settingsSlice.actions.catchError({ error: {clientMessage: "Error Validating Setting"}, callType: callTypes.action }))
          } else {
            dispatch(settingsSlice.actions.settingCreated({ setting: data }));
            onHide()
          }
        })
        .catch(error => {
          error.clientMessage = "Error Creating Setting"
          setErrors([error.clientMessage + ' : ' + error.message])
          setCurrent(setting)
          dispatch(settingsSlice.actions.catchError({ error, callType: callTypes.action }));
        })

    } else {
      // server request for updating setting
      dispatch(settingsSlice.actions.startCall({ callType: callTypes.action }));
      return requestFromServer
        .updateSetting(setting)
        .then(({data}) => {
          if ('errors' in data) {
            let error = []
            data.errors.forEach(e => {
              error.push(('field' in e) ? e.field + ': ' + e.msg : e.msg)
            })
            setErrors(error)
            setCurrent(setting)
            dispatch(settingsSlice.actions.catchError({ error: {clientMessage: "Error Validating Setting"}, callType: callTypes.action }))
          } else {
            dispatch(settingsSlice.actions.settingUpdated({ setting }));
            onHide()
          }
        })
        .catch(error => {
          error.clientMessage = "Error Updating Setting"
          setErrors([error.clientMessage + ' : ' + error.message])
          setCurrent(setting)
          dispatch(settingsSlice.actions.catchError({ error, callType: callTypes.action }));
        });
    }
  };
  
  let i = 0;
  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <SettingEditDialogHeader id={id} />
      {errors.length > 0 && (
        <div className="alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">
            <ul className="m-0">{errors.map(error => (
                <li key={i++}>{error}</li>
              ))}</ul>
          </div>
        </div>
      )}
      <SettingEditForm
        saveSetting={saveSetting}
        actionsLoading={actionsLoading}
        setting={{...settingsUIProps.initSetting, ...settingForEdit, ...current}}
        onHide={onHide}
      />
    </Modal>
  );
}
