/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import * as actions from "./_redux/gatewaysActions";
import { useGatewaysUIContext } from "./GatewaysUIContext";

export function GatewayDeleteDialog() {
  // Gateways UI Context
  const gatewaysUIContext = useGatewaysUIContext();
  const gatewaysUIProps = useMemo(() => {
    return {
      id: gatewaysUIContext.selectedId,
      setIds: gatewaysUIContext.setIds,
      domainId: gatewaysUIContext.domainId,
      queryParams: gatewaysUIContext.queryParams,
      showDeleteGatewayDialog: gatewaysUIContext.showDeleteGatewayDialog,
      closeDeleteGatewayDialog: gatewaysUIContext.closeDeleteGatewayDialog,
    };
  }, [gatewaysUIContext]);

  // Gateways Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.gateways.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!gatewaysUIProps.id) {
      gatewaysUIProps.closeDeleteGatewayDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewaysUIProps.id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteGateway = () => {
    // server request for deleting gateway by id
    dispatch(actions.deleteGateway(gatewaysUIProps.id, gatewaysUIProps.domainId)).then(() => {
      // refresh list after deletion
      dispatch(
        actions.fetchGateways(
          gatewaysUIProps.queryParams,
          gatewaysUIProps.domainId
        )
      );
      // clear selections list
      gatewaysUIProps.setIds([]);
      // closing delete modal
      gatewaysUIProps.closeDeleteGatewayDialog();
    });
  };

  return (
    <Modal
      show={gatewaysUIProps.showDeleteGatewayDialog}
      onHide={gatewaysUIProps.closeDeleteGatewayDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
         <FormattedMessage id="COMMON.DELETE.TITLE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span><FormattedMessage id="COMMON.DELETE.MESSAGE" /></span>
        )}
        {isLoading && <span><FormattedMessage id="COMMON.DELETE.PROGRESS" /></span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={gatewaysUIProps.closeDeleteGatewayDialog}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="COMMON.DIALOG.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteGateway}
            className="btn btn-primary btn-elevate"
          >
            <FormattedMessage id="COMMON.DELETE.SUBMIT" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
