/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../_redux/domainsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import DomainEditForm from "./DomainEditForm";
import { useSubheader } from "../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { GatewaysUIProvider } from "../domain-gateways/GatewaysUIContext";
import { Gateways } from "../domain-gateways/Gateways";

const initDomain = {
    id: undefined,
    name: '',
    key: '',
    currency: '',
    subdomains: [],
    ips: [],
    entities: [],
    active: 0,
    modified: ''
};

const DomainEdit = ({
  intl,
  history,
  match: {
    params: { id },
  },
}) => {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.domains.actionsLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchDomain(id));
  }, [id, dispatch]);

  const { domainForEdit, role } = useSelector(
    (state) => ({
      domainForEdit: state.domains.domainForEdit,
      role: state.auth.user.role
    }),
    shallowEqual
  );

  useEffect(() => {
    let _title = id ? intl.formatMessage({id: 'COMMON.EDIT.TITLE'}) : intl.formatMessage({id: 'COMMON.ADD.TITLE'});
    if (id) {
      _title += ` #${id}`;
    }
    /*
    if(role === 0) {
      _title = intl.formatMessage({id: 'COMMON.TITLE.KEY'})
      _title += ` : ${domainForEdit.key}`;
    }*/
    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const saveDomain = (values) => {
    if (!id) {
      dispatch(actions.createDomain(values)).then(() => backToDomainsList());
    } else {
      dispatch(actions.updateDomain(values)).then(() => backToDomainsList());
    }
  };

  const backToDomainsList = () => {
    history.push(`/domains`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        {role === 1 &&
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={backToDomainsList}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              <FormattedMessage id="COMMON.DIALOG.BACK" />
            </button>
          </CardHeaderToolbar>
        }
      </CardHeader>
      <CardBody>
        <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item" onClick={() => setTab("basic")}>
            <a
              className={`nav-link ${tab === "basic" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "basic").toString()}
            >
              <FormattedMessage id="DOMAINS.SECTION.MAIN" />
            </a>
          </li>
          {id && (
            <>
              {" "}
              <li className="nav-item" onClick={() => setTab("gateways")}>
                <a
                  className={`nav-link ${tab === "gateways" && "active"}`}
                  data-toggle="tab"
                  role="button"
                  aria-selected={(tab === "gateways").toString()}
                >
                  <FormattedMessage id="DOMAINS.SECTION.GATEWAYS" />
                </a>
              </li>
            </>
          )}
        </ul>
        <div className="mt-5">
          {tab === "basic" && (
            <DomainEditForm
              actionsLoading={actionsLoading}
              domain={domainForEdit || initDomain}
              saveDomain={saveDomain}
            />
          )}
          {tab === "gateways" && id && (
            <GatewaysUIProvider currentDomainId={id}>
              <Gateways />
            </GatewaysUIProvider>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default injectIntl(DomainEdit);