import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useGatewaysUIContext } from "./GatewaysUIContext";

export function GatewaysFilter() {
  // Gateways UI Context
  const gatewaysUIContext = useGatewaysUIContext();
  const gatewaysUIProps = useMemo(() => {
    return {
      openNewGatewayDialog: gatewaysUIContext.openNewGatewayDialog,
    };
  }, [gatewaysUIContext]);

  return (
    <>
      <div className="form-filtration">
        <div className="row align-items-center">
          <div className="col-md-12 text-right margin-bottom-10-mobile">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => gatewaysUIProps.openNewGatewayDialog()}
            >
              <FormattedMessage id="COMMON.TABLE.ADD" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
