/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const {user} = useSelector(state => state.auth);

    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="menu-text"><FormattedMessage id="MENU.DASHBOARD" /></span>
                </NavLink>
            </li>
            {
                (user.role === 0) &&
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/domains/'+user.domain+'/edit')}`}>
                    <NavLink className="menu-link" to={'/domains/'+user.domain+'/edit'}>
                        <span className="menu-text"><FormattedMessage id="MENU.DOMAIN" /></span>
                    </NavLink>
                </li>
            }
            {/*end::1 Level*/}
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
