import React, { useMemo } from "react";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useAccountsUIContext } from "../AccountsUIContext";

const prepareFilter = (queryParams, values) => {
  const { active, domain, accountId } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.active = active !== "" ? active : undefined;
  filter.domain = domain !== "" ? domain : undefined;
  filter.accountId = accountId !== "" ? accountId : undefined;
  newQueryParams.filter = filter;
  return newQueryParams;
};

const AccountsFilter = ({ intl, listLoading }) =>  {
  // Accounts UI Context
  const accountsUIContext = useAccountsUIContext();
  const accountsUIProps = useMemo(() => {
    return {
      queryParams: accountsUIContext.queryParams,
      setQueryParams: accountsUIContext.setQueryParams,
    };
  }, [accountsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(accountsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, accountsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      accountsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          active: "",
          domain: "",
          accountId: ""
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="active"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("active", e.target.value);
                    handleSubmit();
                  }}
                  value={values.active}
                >
                  <option value="">{intl.formatMessage({id: 'ACCOUNTS.TYPE.ALL'})}</option>
                  <option value="0">{intl.formatMessage({id: 'ACCOUNTS.TYPE.INACTIVE'})}</option>
                  <option value="1">{intl.formatMessage({id: 'ACCOUNTS.TYPE.ACTIVE'})}</option>
                </select>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="domain"
                  placeholder={intl.formatMessage({id: 'COMMON.TABLE.DOMAIN'})}
                  onBlur={handleBlur}
                  value={values.domain}
                  onChange={(e) => {
                    setFieldValue("domain", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="accountId"
                  placeholder={intl.formatMessage({id: 'COMMON.TABLE.ACCOUNT'})}
                  onBlur={handleBlur}
                  value={values.accountId}
                  onChange={(e) => {
                    setFieldValue("accountId", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default injectIntl(AccountsFilter);