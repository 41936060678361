import React from "react";
import { GatewaysFilter } from "./GatewaysFilter";
import GatewaysTable from "./GatewaysTable";
import GatewaysLoadingDialog from "./GatewaysLoadingDialog";
import { GatewayDeleteDialog } from "./GatewayDeleteDialog";
import { GatewayEditDialog } from "./gateway-edit-dialog/GatewayEditDialog";

export function Gateways() {
  return (
    <>
      <GatewaysLoadingDialog />
      <GatewayEditDialog />
      <GatewayDeleteDialog />
      <div className="form margin-b-30">
        <GatewaysFilter />
      </div>
      <GatewaysTable />
    </>
  );
}
