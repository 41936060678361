import * as requestFromServer from "./domainsCrud";
import {domainsSlice, callTypes} from "./domainsSlice";

const {actions} = domainsSlice;

export const fetchDomains = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDomains(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.domainsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const exportDomains = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .exportDomains(queryParams)
    .then(response => {
      const file = response.data
      dispatch(actions.domainsExported({ exportedFile: file }));
    })
    .catch(error => {
      error.clientMessage = "Can't export domains";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDomain = id => dispatch => {
  if (!id) {
    return dispatch(actions.domainFetched({ domainForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDomainById(id)
    .then(response => {
      const domain = response.data;
      dispatch(actions.domainFetched({ domainForEdit: domain }));
    })
    .catch(error => {
      error.clientMessage = "Can't find domain";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteDomain = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDomain(id)
    .then(response => {
      dispatch(actions.domainDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete domain";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createDomain = domainForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createDomain(domainForCreation)
    .then(response => {
      const domain = response.data;
      dispatch(actions.domainCreated({ domain }));
    })
    .catch(error => {
      error.clientMessage = "Can't create domain";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDomain = domain => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateDomain(domain)
    .then(() => {
      dispatch(actions.domainUpdated({ domain }));
    })
    .catch(error => {
      error.clientMessage = "Can't update domain";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
