import * as requestFromServer from "./gatewaysCrud";
import {gatewaysSlice, callTypes} from "./gatewaysSlice";

const {actions} = gatewaysSlice;

export const fetchGateways = (queryParams, domainId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findGateways(queryParams, domainId)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.gatewaysFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find gateways";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGateway = (id, domainId) => dispatch => {
  if (!id) {
    return dispatch(actions.gatewayFetched({ gatewayForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGatewayById(id, domainId)
    .then(response => {
      const gateway = response.data;
      dispatch(actions.gatewayFetched({ gatewayForEdit: gateway }));
    })
    .catch(error => {
      error.clientMessage = "Can't find gateway";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGateway = (id, domainId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGateway(id, domainId)
    .then(response => {
      dispatch(actions.gatewayDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete gateway";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGateway = (gatewayForCreation, domainId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createGateway(gatewayForCreation, domainId)
    .then(response => {
      const gateway = response.data;
      dispatch(actions.gatewayCreated({ gateway }));
    })
    .catch(error => {
      error.clientMessage = "Can't create gateway";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGateway = (gateway, domainId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGateway(gateway, domainId)
    .then(() => {
      dispatch(actions.gatewayUpdated({ gateway }));
    })
    .catch(error => {
      error.clientMessage = "Can't update gateway";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
