import React from "react";
import { Route } from "react-router-dom";
import DomainsLoadingDialog from "./domains-loading-dialog/DomainsLoadingDialog";
import { DomainDeleteDialog } from "./domain-delete-dialog/DomainDeleteDialog";
import { DomainsUIProvider } from "./DomainsUIContext";
import { DomainsCard } from "./DomainsCard";

export function DomainsPage({ history }) {
  const domainsUIEvents = {
    newDomainButtonClick: () => {
      history.push("/domains/new");
    },
    openEditDomainDialog: (id) => {
      history.push(`/domains/${id}/edit`);
    },
    openDeleteDomainDialog: (id) => {
      history.push(`/domains/${id}/delete`);
    }
  }

  return (
    <DomainsUIProvider domainsUIEvents={domainsUIEvents}>
      <DomainsLoadingDialog />
      <Route path="/domains/:id/delete">
        {({ history, match }) => (
          <DomainDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/domains");
            }}
          />
        )}
      </Route>
      <DomainsCard />
    </DomainsUIProvider>
  );
}