import React from "react";
import { FormattedMessage } from "react-intl";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import SettingsFilter from "./settings-filter/SettingsFilter";
import SettingsTable from "./settings-table/SettingsTable";

export function SettingsCard() {

  return (
    <Card>
      <CardHeader title={<FormattedMessage id="SETTINGS.TITLE" />}>
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <SettingsFilter />
        <SettingsTable />
      </CardBody>
    </Card>
  );
}
