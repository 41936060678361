import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import TransactionsFilter from "./transactions-filter/TransactionsFilter";
import TransactionsTable from "./transactions-table/TransactionsTable";
import { useTransactionsUIContext } from "./TransactionsUIContext";

export function TransactionsCard() {
  const transactionsUIContext = useTransactionsUIContext();
  const transactionsUIProps = useMemo(() => {
    return {
      ids: transactionsUIContext.ids,
      newTransactionButtonClick: transactionsUIContext.newTransactionButtonClick,
      exportTransactionsButtonClick: transactionsUIContext.exportTransactionsButtonClick,
    };
  }, [transactionsUIContext]);

  return (
    <Card>
      <CardHeader title={<FormattedMessage id="TRANSACTIONS.TITLE" />}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-warning"
            onClick={transactionsUIProps.exportTransactionsButtonClick}
          >
            <FormattedMessage id="COMMON.TABLE.EXPORT" />
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <TransactionsFilter />
        <TransactionsTable />
      </CardBody>
    </Card>
  );
}
