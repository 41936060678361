// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import { OverlayTrigger, Tooltip, ListGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export function AccountColumnFormatter(value, row, rowIndex) {
  return (
    <>
      {
        ('account' in value && 'accountId' in value.account && value.account.accountId) ?
          (<OverlayTrigger overlay={<Tooltip>
              <strong>Entity</strong>: {(value.account.entity) ? value.account.entity : (row.domainName === value.account.accountId) ? 'DOMAIN' : 'USER'}
            </Tooltip>}>
            <span className="svg-icon svg-icon-md svg-icon-primary cursor-pointer">
              {value.account.accountId}
            </span>
          </OverlayTrigger>)
        : ('bankDetails' in value) ?
          (<OverlayTrigger overlay={<Tooltip>
              <ListGroup variant="flush">
                <ListGroup.Item><strong>Branch</strong>: {(value.bankDetails.branch) ? value.bankDetails.branch : 'NA'}</ListGroup.Item>
                <ListGroup.Item><strong>IFSC</strong>: {(value.bankDetails.ifsc) ? value.bankDetails.ifsc : 'NA'}</ListGroup.Item>
                <ListGroup.Item><strong>Ac.No.</strong>: {(value.bankDetails.accountNumber) ? value.bankDetails.accountNumber : 'NA'}</ListGroup.Item>
                <ListGroup.Item><strong>Ac.Holder</strong>: {(value.bankDetails.accountHolder) ? value.bankDetails.accountHolder : 'NA'}</ListGroup.Item>
              </ListGroup>
            </Tooltip>}>
            <span className="svg-icon svg-icon-md svg-icon-primary cursor-pointer">
              {value.bankDetails.name}
            </span>
          </OverlayTrigger>)
        : ('gatewayDetails' in value) ?
          (<OverlayTrigger overlay={<Tooltip>

          </Tooltip>}>
          <span className="svg-icon svg-icon-md svg-icon-primary cursor-pointer">
            {value.gatewayDetails.name}
          </span>
          </OverlayTrigger>)
        : ('manualDeposit' in value || 'manualWithdrawal' in value) ?
          (<OverlayTrigger overlay={<Tooltip>

          </Tooltip>}>
          <span className="svg-icon svg-icon-md svg-icon-primary cursor-pointer">
            <FormattedMessage id="COMMON.TITLE.MANUAL" />
          </span>
          </OverlayTrigger>)
        : <></>
      }
    </>
  );
}